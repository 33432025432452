import React from "react"
import { useTranslation } from "@3nvi/gatsby-theme-intl"
import { StaticImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"

import Layout from "../components/layout"

const MSInt = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <div className="both-edges">
        <h2>{t("msinternational.title")}</h2>
        <p>{t("msinternational.body")}</p>
        <GatsbyLink to="https://www.msi-dsl.com/">
          <StaticImage src="../images/msi-defence.jpeg" alt="MSI Defence logo" />
        </GatsbyLink>
        <GatsbyLink to="https://www.msi-forks.com/">
          <StaticImage src="../images/msi-forks.jpeg" alt="MSI Forks logo" />
        </GatsbyLink>
        <GatsbyLink to="https://global-msi.com/">
          <StaticImage src="../images/msi-global.jpeg" alt="MSI Global logo" />
        </GatsbyLink>
      </div>
    </Layout>
  )
}

export default MSInt
